import { useUnit } from "effector-react";
import { $activeAppWithState, $contractWithState, $contracts, choseApp, closeApp } from "./model";
import "./init";
import { BUTTON_KIND, BUTTON_SIZE, HeadingMedium, Modal, SPACE } from "@nilfoundation/ui-kit";
import { useStyletron } from "baseui";
import { DeployForm } from "./DeployForm";
import { ContractManagement } from "./ContractManagement";
import { Button } from "baseui/button";

export const Contracts = () => {
  const [deployedApps, activeApp, contracts] = useUnit([$contractWithState, $activeAppWithState, $contracts]);
  const [css] = useStyletron();
  return (
    <div>
      <Modal
        isOpen={!!activeApp}
        onClose={() => {
          closeApp();
        }}
        size={"80vw"}
      >
        {activeApp?.address ? <ContractManagement /> : <DeployForm />}
      </Modal>
      <HeadingMedium
        className={css({
          marginBottom: SPACE[16],
        })}
      >
        Contracts
      </HeadingMedium>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          flex: "0 0",
          flexWrap: "nowrap",
          gap: SPACE[16],
        })}
      >
        {contracts.map((contract) => {
          return (
            <div
              key={contract.bytecode}
              className={css({
                background: "#212121",
                borderRadius: "8px",
                padding: SPACE[8],
                display: "flex",
                flexDirection: "column",
              })}
            >
              <div
                className={css({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: SPACE[8],
                })}
              >
                <HeadingMedium
                  className={css({
                    wordBreak: "break-word",
                    paddingRight: SPACE[8],
                  })}
                >
                  {contract.name}
                </HeadingMedium>
                <Button
                  onClick={() => {
                    choseApp({
                      bytecode: contract.bytecode,
                    });
                  }}
                  size={BUTTON_SIZE.compact}
                  kind={BUTTON_KIND.primary}
                >
                  Deploy
                </Button>
              </div>
              <div>
                {deployedApps
                  .filter((app) => app.bytecode === contract.bytecode)
                  .map(({ address, bytecode }) => {
                    return (
                      <Button
                        overrides={{
                          Root: {
                            style: {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whitespace: "nowrap",
                              marginBottom: SPACE[8],
                              width: "100%",
                              display: "inline-block",
                            },
                          },
                        }}
                        key={address}
                        size={BUTTON_SIZE.compact}
                        kind={BUTTON_KIND.secondary}
                        onClick={() => {
                          choseApp({ address, bytecode });
                        }}
                      >
                        {address}
                      </Button>
                    );
                  })}
                {deployedApps.filter((app) => app.bytecode === contract.bytecode).length === 0 && (
                  <Button
                    overrides={{
                      Root: {
                        style: {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whitespace: "nowrap",
                          marginBottom: SPACE[8],
                          width: "100%",
                          display: "inline-block",
                        },
                      },
                    }}
                    size={BUTTON_SIZE.compact}
                    kind={BUTTON_KIND.secondary}
                    onClick={() => {
                      choseApp({ bytecode: contract.bytecode });
                    }}
                  >
                    Deploy new smart contract
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
