import {
  BUTTON_KIND,
  Button,
  COLORS,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  FormControl,
  Input,
  NOTIFICATION_KIND,
  ParagraphMedium,
  SPACE,
  Notification,
} from "@nilfoundation/ui-kit";
import type { AbiFunction } from "abitype";
import { useStyletron } from "baseui";
import { toggleActiveKey } from "./model";
import { Link } from "../shared";
import { transactionRoute } from "../routing";

export type MethodProps = {
  func: AbiFunction;
  isOpen: boolean;
  index: number;
  handler: (funcName: string) => void;
  error?: string;
  result?: unknown;
  loading?: boolean;
  txHash?: string;
  params?: Record<string, unknown>;
  paramsHandler: (params: { functionName: string; paramName: string; value: unknown }) => void;
};

export const Method = ({
  func,
  isOpen,
  index,
  handler,
  error,
  result,
  loading,
  txHash,
  paramsHandler,
  params,
}: MethodProps) => {
  const [css] = useStyletron();
  return (
    <div
      key={func.name}
      className={css({
        paddingBottom: SPACE[16],
        borderBottom: `1px solid ${COLORS.gray800}`,
        display: "flex",
        flexDirection: "column",
        flex: "0 0",
      })}
    >
      <Button
        kind={BUTTON_KIND.secondary}
        className={css({
          marginBottom: isOpen ? SPACE[16] : 0,
          flexGrow: 0,
        })}
        endEnhancer={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={() => {
          toggleActiveKey(func.name);
        }}
        isLoading={loading}
      >
        {index}. {func.name}
      </Button>
      {isOpen && (
        <>
          {func.inputs.length > 0 && (
            <Card
              overrides={{
                Root: {
                  style: {
                    marginBottom: SPACE[16],
                  },
                },
              }}
            >
              {func.inputs.map((input, index) => {
                const key = input.name || `${index}`;
                return (
                  <div key={key}>
                    <FormControl label={input.name} caption={input.type}>
                      <Input
                        value={params ? `${params[key]}` || "" : ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          paramsHandler({ functionName: func.name, paramName: key, value });
                        }}
                      />
                    </FormControl>
                  </div>
                );
              })}
            </Card>
          )}
          <Button
            onClick={() => {
              handler(func.name);
            }}
          >
            Call
          </Button>
          {result && (
            <Notification kind={NOTIFICATION_KIND.info}>
              <ParagraphMedium
                className={css({
                  wordBreak: "break-all",
                })}
              >
                Result: {`${result}`}
              </ParagraphMedium>
            </Notification>
          )}
          {txHash && (
            <Notification kind={NOTIFICATION_KIND.positive}>
              Transaction sent with hash{" "}
              <Link
                to={transactionRoute}
                params={{ hash: txHash }}
                className={css({
                  wordBreak: "break-all",
                })}
              >
                {txHash}
              </Link>
            </Notification>
          )}
          {error && <Notification kind={NOTIFICATION_KIND.negative}>{error}</Notification>}
        </>
      )}
    </div>
  );
};
