import { useUnit } from "effector-react";
import { collapseLog, expandLog } from "./model";
import {
  BUTTON_KIND,
  BUTTON_SIZE,
  Button,
  COLORS,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  HeadingMedium,
  MonoParagraphMedium,
  SPACE,
} from "@nilfoundation/ui-kit";
import "./init";
import { $logsWithOpen } from "./init";
import { useStyletron } from "baseui";

export const Logs = () => {
  const [logs] = useUnit([$logsWithOpen]);
  const [css] = useStyletron();
  return (
    <Card
      title="Logs"
      overrides={{
        Root: {
          style: {
            backgroundColor: "#212121",
            width: "100%",
            maxWidth: "none",
            height: "100%",
          },
        },
        Contents: {
          style: {
            display: "flex",
            flexDirection: "column",
            gap: SPACE[8],
            height: "100%",
          },
        },
        Body: {
          style: {
            overflow: "auto",
            flex: "1 0",
          },
        },
      }}
    >
      {logs.map((log, index) => {
        return (
          <div
            key={log.id}
            className={css({
              paddingBottom: SPACE[8],
              borderBottom: index === logs.length - 1 ? "none" : `1px solid ${COLORS.gray800}`,
              marginBottom: SPACE[8],
            })}
          >
            <div
              className={css({
                cursor: "pointer",
                marginBottom: log.isOpen ? SPACE[8] : 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              })}
              onMouseDown={(e) => {
                e.preventDefault();
                log.isOpen ? collapseLog(log.id) : expandLog(log.id);
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                log.isOpen ? collapseLog(log.id) : expandLog(log.id);
              }}
            >
              <HeadingMedium>{log.shortDescription}</HeadingMedium>
              <Button
                kind={BUTTON_KIND.secondary}
                size={BUTTON_SIZE.compact}
                className={css({
                  marginLeft: SPACE[8],
                })}
              >
                {log.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </div>
            {log.isOpen && (
              <Card
                overrides={{
                  Root: {
                    style: {
                      wordBreak: "break-all",
                      width: "100%",
                      maxWidth: "none",
                    },
                  },
                }}
              >
                <MonoParagraphMedium>
                  {JSON.stringify(
                    log.payload,
                    (_, value) => (typeof value === "bigint" ? `${value.toString(10)}n` : value),
                    2,
                  )}
                </MonoParagraphMedium>
              </Card>
            )}
          </div>
        );
      })}
    </Card>
  );
};
