import { useUnit } from "effector-react";
import { $code, $error, changeCode, compile, fetchCodeSnippetFx, loadedPage } from "./model";
import { BUTTON_KIND, BUTTON_SIZE, Button, COLORS, Card, CodeField, Spinner } from "@nilfoundation/ui-kit";
import "./init";
import { useStyletron } from "baseui";
import { solidity } from "@replit/codemirror-lang-solidity";
import { type EditorView, type Extension, basicSetup } from "@uiw/react-codemirror";
import { memo, useEffect, useMemo } from "react";
import { fetchSolidityCompiler } from "../../services/compiler";
import { linter, type Diagnostic } from "@codemirror/lint";
import { ShareCodePanel } from "./ShareCodePanel";

const MemoizedShareCodePanel = memo(ShareCodePanel);

export const Code = () => {
  const [code, isDownloading, errors, fetchingCodeSnippet] = useUnit([
    $code,
    fetchSolidityCompiler.pending,
    $error,
    fetchCodeSnippetFx.pending,
  ]);
  const [css] = useStyletron();
  useEffect(() => {
    loadedPage();
  }, []);
  const codemirrorExtensions = useMemo<Extension[]>(() => {
    const solidityLinter = (view: EditorView) => {
      const diagnostics: Diagnostic[] = errors.map((error) => {
        return {
          from: view.state.doc.line(error.line).from,
          to: view.state.doc.line(error.line).to,
          message: error.message,
          severity: "error",
        };
      });
      return diagnostics;
    };
    return [solidity, ...basicSetup(), linter(solidityLinter)];
  }, [errors]);

  const noCode = code.trim().length === 0;

  return (
    <Card
      title="Code"
      overrides={{
        Root: {
          style: {
            backgroundColor: COLORS.gray900,
            width: "100%",
            maxWidth: "none",
          },
        },
        Body: {
          style: {
            display: "grid",
            gap: "16px",
            gridTemplateRows: "1fr auto",
            gridTemplateColumns: "min-content 1fr",
          },
        },
      }}
    >
      <div
        className={css({
          width: "100%",
          height: "calc(100vh - 428px)",
          // minHeight: "400px",
          overflow: "auto",
          gridColumn: "1 / 3",
        })}
      >
        {fetchingCodeSnippet ? (
          <Spinner />
        ) : (
          <CodeField
            extensions={codemirrorExtensions}
            editable
            readOnly={false}
            code={code}
            onChange={(text) => {
              changeCode(`${text}`);
            }}
          />
        )}
      </div>
      <Button
        kind={BUTTON_KIND.primary}
        isLoading={isDownloading}
        size={BUTTON_SIZE.compact}
        onClick={() => compile()}
        disabled={noCode}
      >
        Compile
      </Button>
      <MemoizedShareCodePanel disabled={isDownloading || noCode} />
    </Card>
  );
};
