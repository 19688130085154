import { useStyletron } from "styletron-react";
import { styles } from "./styles";
import { BUTTON_KIND, Button, COLORS, CopyButton, LabelMedium } from "@nilfoundation/ui-kit";
import { EndpointInput } from "./EndpointInput";
import { $balance, createWalletFx, regenrateAccountEvent, topUpEvent, topUpWalletFx } from "../models/model";
import { useUnit } from "effector-react";
import { measure } from "../../shared";
import type { ButtonOverrides } from "baseui/button";

type AccountMenuProps = {
  address: string | null;
};

const btnOverrides: ButtonOverrides = {
  Root: {
    style: ({ $disabled }) => ({
      backgroundColor: $disabled ? `${COLORS.gray400}!important` : "",
    }),
  },
};

const AccountMenu = ({ address }: AccountMenuProps) => {
  const [css] = useStyletron();
  const [isPendingWalletCreation] = useUnit([createWalletFx.pending]);
  const [balance] = useUnit([$balance]);
  const [isPendingTopUp] = useUnit([topUpWalletFx.pending]);
  const displayBalance = balance === null ? "-" : measure(balance);

  return (
    <ul className={css(styles.menu)} role="menu">
      <li className={css(styles.menuItem)}>
        {address !== null && (
          <>
            <LabelMedium>{address}</LabelMedium>
            <CopyButton textToCopy={address} disabled={address === null} />
          </>
        )}
        {isPendingWalletCreation && <LabelMedium>Creating new wallet...</LabelMedium>}
      </li>
      <li className={css(styles.divider)} />
      <li className={css(styles.menuItem)}>
        <EndpointInput />
      </li>
      <li className={css(styles.divider)} />
      <li className={css(styles.menuItem)}>
        <LabelMedium>Balance:</LabelMedium>
        <LabelMedium>{displayBalance}</LabelMedium>
      </li>
      <li className={css(styles.divider)} />
      <li className={css(styles.menuItem)}>
        <Button
          kind={BUTTON_KIND.primary}
          onClick={() => topUpEvent()}
          isLoading={isPendingTopUp}
          disabled={isPendingTopUp || isPendingWalletCreation}
          overrides={btnOverrides}
        >
          Top up wallet
        </Button>
        <Button kind={BUTTON_KIND.toggle} onClick={() => regenrateAccountEvent()}>
          Regenerate account
        </Button>
      </li>
    </ul>
  );
};

export { AccountMenu };
