import { useUnit } from "effector-react";
import { useMobile } from "../../features/shared";
import { ProgressBar, PROGRESS_BAR_SIZE, SPACE } from "@nilfoundation/ui-kit";
import { $activeComponents, LayoutComponent } from "./model";
import { Code } from "../../features/code/Code";
import { Contracts } from "../../features/contracts/Contracts";
import { Logs } from "../../features/logs/Logs";
import { fetchSolidityCompiler } from "../../services/compiler";
import { useStyletron } from "baseui";
import { Navbar } from "../../features/shared/components/Layout/Navbar";
import { mobileContainerStyle, styles } from "../../features/shared/components/Layout/styles";
import { AccountConnectorProvider, AccountPane } from "../../features/account-connector";

const featureMap = new Map();
featureMap.set(LayoutComponent.Code, Code);
featureMap.set(LayoutComponent.Logs, Logs);
featureMap.set(LayoutComponent.Contracts, Contracts);

export const SandboxPage = () => {
  const [_, isDownloading] = useUnit([$activeComponents, fetchSolidityCompiler.pending]);
  const [css] = useStyletron();
  const [isMobile] = useMobile();

  return (
    <div className={css(isMobile ? mobileContainerStyle : styles.container)}>
      <AccountConnectorProvider>
        <Navbar>
          <AccountPane />
        </Navbar>
        <div
          className={css({
            width: "100%",
            marginTop: SPACE[32],
            height: "calc(100vh - 128px)",
          })}
        >
          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "1fr 240px",
              gridTemplateRows: "1fr 150px",
              gap: "16px",
              padding: "16px",
              width: "100%",
              height: "100%",
            })}
          >
            <Code />
            <Contracts />
            <Logs />
          </div>
          {isDownloading && (
            <ProgressBar size={PROGRESS_BAR_SIZE.large} minValue={0} maxValue={100} value={1} infinite />
          )}
        </div>
      </AccountConnectorProvider>
    </div>
  );
};
